<template>
  <v-container fluid class="px-auto">
    <v-text-field
      v-model="brickId"
      label="Brick-ID des Bausteins"
      type="text"
      filled
      @keyup="commit()">
    </v-text-field>
    <v-text-field
      v-model="stellen"
      label="Anzahl der Stellen"
      type="number"
      filled
      @keyup="commit()">
    </v-text-field>
    <v-textarea
      name="result"
      filled
      readonly
      label="Formel zum Einfügen"
      auto-grow
      disabled
      v-model="string"
    ></v-textarea>
  </v-container>
</template>

<script>

export default {
  name: 'CSV2DataSource',
  data: () => ({
    brickId: 'Textbox',
    stellen: 5,
    string: '',
    ersteStelle: 'IF (GREATERTHANOREQUAL(LENGTH({Textbox}),1),IF(EQUALS(RIGHT({Textbox},1)"1"),1, IF(EQUALS(RIGHT({Textbox},1)"2"),2, IF(EQUALS(RIGHT({Textbox},1)"3"),3, IF(EQUALS(RIGHT({Textbox},1)"4"),4, IF(EQUALS(RIGHT({Textbox},1)"5"),5, IF(EQUALS(RIGHT({Textbox},1)"6"),6, IF(EQUALS(RIGHT({Textbox},1)"7"),7, IF(EQUALS(RIGHT({Textbox},1)"8"),8, IF(EQUALS(RIGHT({Textbox},1)"9"),9))))))))),0)'
  }),
  methods: {
    stage(i){
      let stage = 'IF (GREATERTHANOREQUAL(LENGTH({Textbox}),$),IF(EQUALS(LEFT(RIGHT({Textbox},$),1)"1"),1%, IF(EQUALS(LEFT(RIGHT({Textbox},$),1)"2"),2%, IF(EQUALS(LEFT(RIGHT({Textbox},$),1)"3"),3%, IF(EQUALS(LEFT(RIGHT({Textbox},$),1)"4"),4%, IF(EQUALS(LEFT(RIGHT({Textbox},$),1)"5"),5%, IF(EQUALS(LEFT(RIGHT({Textbox},$),1)"6"),6%, IF(EQUALS(LEFT(RIGHT({Textbox},$),1)"7"),7%, IF(EQUALS(LEFT(RIGHT({Textbox},$),1)"8"),8%, IF(EQUALS(LEFT(RIGHT({Textbox},$),1)"9"),9%))))))))),0)'
      const nullen = "0".repeat(i-1)
      console.log(nullen)
      stage = stage.replaceAll('$',i).replaceAll("%",nullen)
      return stage
    },

    commit(){
      let string = this.ersteStelle
      if (this.stellen > 1) {
        for (let i = 2; i <= this.stellen; i++){
          string = string+','+this.stage(i)
        }
      }
      string = 'SUM('+string+')'
      this.string = string.replaceAll('Textbox',this.brickId)
    }
  },
  mounted(){
    this.commit()
  }
}
</script>